import React from 'react';

import { Avatar, Box, Typography } from '@mui/material';

import { Counselor, PrivateCounselor, QualificationEvidence } from 'graphql/graphql-interfaces';
import PagePaths from 'pagepaths';
import FlexContainer from 'layouts/FlexContainer';
import RenderLink from './RenderLink';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

interface CounselorInSearchProps {
  counselor: Counselor;
}
const CounselorInSearch: React.FC<CounselorInSearchProps> = ({ counselor }) => {
  const qualifications = (counselor: PrivateCounselor | Counselor): string[] => {
    if (!counselor.qualificationEvidences || counselor.qualificationEvidences.length === 0) {
      return [];
    }
    const qualificationEvidences: QualificationEvidence[] = counselor.qualificationEvidences;
    const nowTimestamp = dayjs().unix();
    const validQualifications = qualificationEvidences
      .map((evidence) => {
        if (
          evidence.evidenceConfirmationStatus === 'confirmed' &&
          evidence.qualificationName === '臨床心理士' &&
          evidence.expiredTimestamp &&
          evidence.expiredTimestamp > nowTimestamp
        ) {
          return evidence.qualificationName;
        } else if (evidence.evidenceConfirmationStatus === 'confirmed' && evidence.qualificationName === '公認心理師') {
          return evidence.qualificationName;
        } else {
          return undefined;
        }
      })
      .filter((item): item is Exclude<typeof item, undefined> => item !== undefined);

    return validQualifications;
  };

  return (
    <FlexContainer
      sx={{
        boxShadow: 4,
        borderRadius: 3,
        margin: 1,
        padding: 2,
        flex: 0,
      }}
      component={RenderLink(PagePaths.counselor + '/' + counselor.id)}
    >
      <Box sx={{ display: 'flex' }}>
        <Avatar src={counselor.profileImage || ''} sx={{ width: '5rem', height: '5rem', mr: 2 }}>
          {counselor.lastName}
        </Avatar>
        <Box>
          <Typography variant="h5">{`${counselor.lastName} ${counselor.firstName}`}</Typography>
          <Typography variant="body1">{qualifications(counselor)?.join(' / ')}</Typography>
        </Box>
      </Box>
      <Typography variant="body1">{counselor.description}</Typography>
    </FlexContainer>
  );
};

export default CounselorInSearch;
