import { Auth } from '@aws-amplify/auth';

import { getProfile, clearProfile, putProfile, dbUpdateConfig } from 'db';
import { PrivateUserType, UserType } from 'graphql/graphql-interfaces';
import { convertPhoneNumberToGlobal } from 'lib/utils';
import { group } from 'env';
import logger from 'lib/logger';
import { getUser } from 'controllers';

import loadAwsConfig from 'awsConfig';
loadAwsConfig();

export interface CognitoAttributes {
  email: string;
  email_verified: boolean;
  phone_number: string;
  phone_number_verified: boolean;
  birthdate?: string;
  family_name?: string;
  given_name?: string;
  gender?: string;
  'custom:stripe': string;
  'custom:family_name_kana': string;
  'custom:given_name_kana': string;
  sub: string;
}

export interface CognitoUser {
  attributes: CognitoAttributes;
  id: string;
  username: string;
}

const isPrivateUser = (user: UserType): user is PrivateUserType =>
  user.__typename === 'PrivateCounselor' || user.__typename === 'PrivatePatient';

// Amplify の Auth をラップして IndexedDB につなぐサービス
class AuthService {
  static updateProfile = async () => {
    try {
      const cognitoUser = await AuthService.currentUserInfo();
      const id = cognitoUser.attributes.sub;
      const user = await getUser(id);
      const privateUser = user && isPrivateUser(user) ? user : undefined;
      const newProfile = { id, cognitoUser, privateUser };
      logger.log(newProfile);

      return putProfile(newProfile);
    } catch (error) {
      logger.error(error);
      return;
    }
  };

  static updatePrivateUser = async () => {
    try {
      const oldProfile = await getProfile();
      if (oldProfile) {
        const id = oldProfile.cognitoUser.attributes.sub;
        const user = await getUser(id);
        const privateUser = user && isPrivateUser(user) ? user : undefined;
        const newProfile = { id, cognitoUser: oldProfile.cognitoUser, privateUser };
        logger.log(newProfile);
        return putProfile(newProfile);
      } else {
        logger.warn('no profile.');
        return;
      }
    } catch (error) {
      logger.error(error);
      return;
    }
  };

  static updateUserAttributes = async (attributes: Partial<CognitoAttributes>) => {
    try {
      const cognitoUser = await AuthService.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, attributes);
      await AuthService.updateProfile();
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static signUp = async (username: string, password: string, phoneNumber: string, email: string) => {
    const globalPhoneNumber = convertPhoneNumberToGlobal(phoneNumber);
    try {
      return Auth.signUp({
        username,
        password,
        attributes: { phone_number: globalPhoneNumber, email, 'custom:group': group },
      });
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static resendSignUp = async (username: string) => {
    try {
      await Auth.resendSignUp(username);
      logger.log('resend code succeeded');
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static signIn = async (username: string, password: string) => {
    await Auth.signIn(convertPhoneNumberToGlobal(username), password, {
      group: process.env.REACT_APP_TARGET as string,
    });
    return Promise.all([AuthService.updateProfile(), dbUpdateConfig({ acceptedTermsOfService: true })]);
  };

  static signOut = async () => Promise.all([Auth.signOut(), clearProfile()]);

  static verifyCurrentUserAttribute = async (name: string) => {
    try {
      await Auth.verifyCurrentUserAttribute(name);
      logger.log('resent code to' + name);
      return AuthService.updateProfile();
    } catch (error) {
      logger.error(error);
    }
  };

  static confirmSignUp = async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
      logger.log('confirmSignup verification succeeded');
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  /** User info and current session */
  static currentAuthenticatedUser = async () => {
    const cognitoUser: CognitoUser | null = await Auth.currentAuthenticatedUser();
    if (!cognitoUser) {
      clearProfile();
      throw new Error('No current user');
    }
    return cognitoUser;
  };

  /** User info only */
  static currentUserInfo = async () => {
    const cognitoUser: CognitoUser | null = await Auth.currentUserInfo();
    if (!cognitoUser) {
      clearProfile();
      throw new Error('No current user');
    }
    return cognitoUser;
  };

  static verifyCurrentUserAttributeSubmit = async (attr: string, code: string) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attr, code);
      await AuthService.updateProfile();
      logger.log(`${attr} verification succeeded`);
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const cognitoUser = await AuthService.currentAuthenticatedUser();
      await Auth.changePassword(cognitoUser, oldPassword, newPassword);
      logger.log('password changed');
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };
}

export default AuthService;
