import { styled } from '@mui/material';
import React from 'react';

interface Props<T> {
  component?: T;
  align?: 'top' | 'center' | 'bottom';
  direction?: 'column' | 'row';
}

type BaseLayoutType = <T extends React.ElementType>(
  // eslint-disable-next-line no-unused-vars
  props: Props<T> & React.ComponentProps<T>
) => React.ReactElement<T>;

const FlexContainer = styled<BaseLayoutType>((props) => {
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { component: Component = 'div', align, direction, ...p } = props;
  return <Component {...p} />;
})(
  ({ align = 'top', direction = 'column' }) => `
  display: flex;
  flex: 1;
  flex-direction: ${direction};
  justify-content: ${align};
  align-items: center;
  width: 100%;

  & > * {
    width: 100%;
  }
`
);

export default FlexContainer;
