import React, { useEffect } from 'react';

import { MessageType } from 'graphql/graphql-interfaces';
import { putReservation, useProfile } from 'db';
import logger from 'lib/logger';
import { getAllUserIds, refreshUser, subscribeMessage, syncAllMessages, getReservations } from 'controllers';

const GraphQlProvider: React.FC = (props) => {
  const profile = useProfile();
  const updatedUserIds = new Set<string>();

  const syncMessages = async () => {
    const newMessages = await syncAllMessages();
    if (newMessages) syncUsers(newMessages);
  };
  const startSubscribe = async (id: string, timeout = 100) => {
    const subscription = subscribeMessage(id, syncUser);
    subscription.catch(() => {
      setTimeout(() => startSubscribe(id, timeout * 2), timeout);
    });
  };

  const updateUser = async (id: string) => {
    if (!updatedUserIds.has(id)) {
      updatedUserIds.add(id);
      await refreshUser(id);
    }
  };

  const syncUser = async (message: MessageType) => {
    try {
      updateUser(message.destId);
      updateUser(message.fromId);
    } catch (error) {
      logger.error(error);
    }
  };

  const syncUsers = async (messages: MessageType[]) => messages.forEach(syncUser);

  const syncCalendarItems = async () => {
    const reservations = await getReservations();
    reservations?.forEach((reservation) => {
      putReservation(reservation);
      updateUser(reservation.counselorId);
      updateUser(reservation.patientId);
    });
  };

  useEffect(() => {
    if (!profile?.id) return;

    try {
      getAllUserIds().then((ids) => ids.map(updateUser));
      syncMessages();
      syncCalendarItems();
      startSubscribe(profile.id);
    } catch (error) {
      logger.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  return <>{props.children}</>;
};

export default GraphQlProvider;
