import { Message, MessageTypeNames } from 'graphql/graphql-interfaces';

type EncryptedText = string;

export interface UserModel {
  id: string;
  user: EncryptedText;
}

export interface MessageModel extends Message {
  __typename: MessageTypeNames;
  id: string;
  fromId: string;
  destId: string;
  timestamp: number;
  text: EncryptedText;
}
export interface ReservationModel {
  id: string;
  timestamp: number;
  data: EncryptedText;
}
export interface AppConfigModel {
  acceptedTermsOfService: boolean;
  skipWelcome: boolean;
}

export const profileKey = 'profile_v1';
export interface ProfileModel {
  key: typeof profileKey;
  id: string;
  cognitoUser: EncryptedText;
  privateUser?: EncryptedText;
}

export interface RoomModel {
  id: string;
  lastMessage?: MessageModel;
  reservation?: ReservationModel;
}

export interface NotificationModel {
  id: string;
  __typename: 'News' | 'Push';
  target: string;
  timestamp: number;
  title: string;
  md: string;
}

export interface TempModel {
  key: string;
  data: string;
}
