import crypto from 'crypto';
import logger from 'lib/logger';

interface EncryptParams {
  method?: crypto.CipherGCMTypes | crypto.CipherCCMTypes;
  encoding?: BufferEncoding;
  iv?: string;
  key?: string;
  header?: string;
}
// eslint-disable-next-line @typescript-eslint/ban-types
type DataTypes = string | object;

export default class CustomCrypto {
  readonly method: crypto.CipherGCMTypes | crypto.CipherCCMTypes | 'aes-256-cbc';
  readonly encoding: BufferEncoding;
  private readonly iv: Buffer;
  private readonly key: crypto.CipherKey;
  private readonly header: string;
  private readonly delimiter = '::';

  constructor(props?: EncryptParams) {
    this.method = props?.method ?? 'aes-256-cbc';
    this.encoding = props?.encoding ?? 'hex';
    this.iv = props?.iv ? Buffer.from(props.iv) : crypto.randomBytes(16);
    this.key = props?.key ? Buffer.from(props.key) : crypto.randomBytes(32);
    this.header = props?.header ?? 'ENC';
  }

  private appendHeader(encrypted: Buffer) {
    const encryptedEncorded = encrypted.toString(this.encoding);
    return [this.header, encryptedEncorded].join(this.delimiter);
  }

  private extractHeader(text: string) {
    const textParts = text.split(this.delimiter);
    const header = textParts.shift();
    if (!header || header !== this.header) {
      logger.warn('input is not encrypted');
      return text;
    } else {
      const encryptedEncorded = textParts[0];
      return encryptedEncorded;
    }
  }

  encrypt<T extends DataTypes>(data: T) {
    const text = typeof data === 'string' ? data : JSON.stringify(data);
    const cipher = crypto.createCipheriv(this.method, this.key, this.iv);
    const updated = cipher.update(text);
    const encrypted = Buffer.concat([updated, cipher.final()]);
    const encryptedWithHeader = this.appendHeader(encrypted);
    return encryptedWithHeader;
  }

  decrypt(text: string) {
    try {
      const encryptedEncorded = this.extractHeader(text);
      const encrypted = Buffer.from(encryptedEncorded, this.encoding);
      const decipher = crypto.createDecipheriv(this.method, this.key, this.iv);
      const updated = decipher.update(encrypted);
      const decrypted = Buffer.concat([updated, decipher.final()]);
      const decryptedStr = decrypted.toString();
      return decryptedStr;
    } catch (error) {
      logger.error(error);
      return text;
    }
  }
}
