import Dexie from 'dexie';

import {
  AppConfigModel,
  ProfileModel,
  MessageModel,
  NotificationModel,
  UserModel,
  RoomModel,
  ReservationModel,
  TempModel,
} from 'db/models';

export const initialConfig: AppConfigModel = {
  acceptedTermsOfService: false,
  skipWelcome: false,
};

export class AppDatabase extends Dexie {
  config!: Dexie.Table<AppConfigModel, number>;
  profile!: Dexie.Table<ProfileModel, string>;
  temp!: Dexie.Table<TempModel, string>;
  users!: Dexie.Table<UserModel, string>;
  rooms!: Dexie.Table<RoomModel, string>;
  messages!: Dexie.Table<MessageModel, string>;
  reservations!: Dexie.Table<ReservationModel, string>;
  notifications!: Dexie.Table<NotificationModel, string>;

  constructor() {
    super('OmamoriDatabase');

    this.version(1).stores({
      config: '++id',
      profile: 'key',
      temp: 'key',
      users: 'id',
      rooms: 'id, lastMessage.timestamp, reservation.timestamp',
      messages: 'id, __typename, fromId, destId, timestamp',
      reservations: 'id, timestamp',
      notifications: 'id, __typename, timestamp',
    });

    this.on('populate', () => {
      this.config.put(initialConfig);
    });
  }
}
