import React from 'react';

import { ListItem, ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material';

import RenderLink from 'components/RenderLink';

interface ListItemLinkProps {
  time?: React.ReactNode;
  icon?: React.ReactElement;
  avatar?: React.ReactElement;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  to: string;
  alignItems?: 'center' | 'flex-start';
}

function ListItemLink(props: ListItemLinkProps) {
  const { avatar, icon, primary, secondary, to, time, alignItems } = props;

  return (
    <li>
      <ListItem button={true} component={RenderLink(to)} alignItems={alignItems}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        {avatar ? <ListItemAvatar>{avatar}</ListItemAvatar> : null}
        <ListItemText primary={primary} secondary={secondary} />
        {time ? <ListItemText style={{ flexShrink: 0, flexGrow: 0 }} primary="" secondary={time} /> : null}
      </ListItem>
    </li>
  );
}

export default ListItemLink;
