import { RouteProps, Redirect, Route } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@mui/material';

import { targetIsPatient } from 'env';
import { PagePaths as p } from 'pagepaths';
import AuthService from 'services/auth-service';
import { clearProfile, useProfile } from 'db';
import { clearDb, isValidDataOwner } from '../db/actions';
import logger from 'lib/logger';
import RedirectRoute from 'routes/RedirectRoute';
import { useSnackbar } from 'components/SnackbarProvider';
import { PrivateCounselor } from 'graphql/graphql-interfaces';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';

dayjs.extend(timezone);
dayjs.extend(utc);

const AuthRoute = (props: RouteProps) => {
  const profile = useProfile();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (profile) {
      AuthService.currentAuthenticatedUser()
        .then((user) => {
          const nowTimestamp = dayjs().unix();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((user as any).signInUserSession.accessToken.payload?.exp < nowTimestamp) {
            logger.warn("Current user's access token is expired.");
            clearProfile();
          }
          if (user) return isValidDataOwner(user.attributes.sub);
          else return true;
        })
        .then((isVerified) => {
          if (!isVerified) {
            logger.log('Cached user profile does not match authenticated user, Indexed Db is cleared.');
            return clearDb();
          }
        });
    }
  });

  if (!profile) {
    if (profile === undefined) {
      // loading profile
      logger.log({ msg: 'AuthRoute', state: 'loading profile' });
      return (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      // 未ログイン
      logger.log({ msg: 'AuthRoute', state: 'user not logged in' });
      logger.log({ msg: 'AuthRoute', to: p.signin });
      return <Redirect to={p.signin} />;
    }
  }

  const { cognitoUser } = profile;
  // 電話番号認証がまだのユーザー
  if (!cognitoUser.attributes.phone_number_verified) {
    logger.log({ msg: 'AuthRoute', to: p.verifyPhone });
    enqueueSnackbar({ message: '電話番号認証を完了してください', variant: 'success' });
    return <Redirect to={p.verifyPhone} />;
  }

  // カウンセラーかつメール認証がまだのユーザー
  if (!targetIsPatient && !cognitoUser.attributes.email_verified) {
    logger.log({ msg: 'AuthRoute', to: p.verifyEmail });
    AuthService.verifyCurrentUserAttribute('email');
    enqueueSnackbar({ message: 'メール認証を完了してください', variant: 'success' });
    return <Redirect to={p.verifyEmail} />;
  }

  // カウンセラーかつCognito属性登録がまだのユーザー
  if (!targetIsPatient && !cognitoUser.attributes.birthdate) {
    logger.log({ msg: 'AuthRoute', to: p.registerAttributes });
    return <RedirectRoute {...props} to={p.registerAttributes} />;
  }

  const { privateUser } = profile;
  // ユーザー情報登録がまだのユーザー
  if (!privateUser) {
    const to = targetIsPatient ? p.registerAttributes : p.registerStripeAccount;
    logger.log({ msg: 'AuthRoute', to });
    return <RedirectRoute {...props} to={to} />;
  }

  // カウンセラーかつ情報不足のため Stripe アカウントが無効化されているユーザー
  if (!targetIsPatient && (privateUser as PrivateCounselor).stripeCurrentDisabledReason === 'past_due') {
    logger.log({ msg: 'AuthRoute', to: p.registerStripeAccount });
    return <RedirectRoute {...props} to={p.registerStripeAccount} />;
  }

  // ban されたユーザ
  if (privateUser.ban) {
    return <RedirectRoute {...props} to={p.banned} />;
  }

  type QualificationRegistrationStatus = 'no_qualification' | 'pending' | 'valid_qualification' | 'other';
  const qualificationRegistrationStatus = (counselor: PrivateCounselor): QualificationRegistrationStatus => {
    if (!counselor.qualificationEvidences || counselor.qualificationEvidences.length === 0) {
      return 'no_qualification';
    }
    const qualificationEvidences = counselor.qualificationEvidences;
    const nowTimestamp = dayjs().unix();
    const validEvidences = qualificationEvidences.filter((evidence) => {
      return (
        (evidence.__typename === 'ConfirmedCPQualificationEvidence' && evidence.expiredTimestamp > nowTimestamp) ||
        evidence.__typename === 'ConfirmedCPPQualificationEvidence'
      );
    });
    if (validEvidences.length) {
      return 'valid_qualification';
    }
    const pendingEvidences = qualificationEvidences.filter((evidence) => {
      return evidence.evidenceConfirmationStatus === 'uploaded';
    });
    if (pendingEvidences.length) {
      return 'pending';
    }
    return 'other';
  };

  // カウンセラーかつ資格確認中のユーザ
  if (privateUser.__typename === 'PrivateCounselor' && qualificationRegistrationStatus(privateUser) === 'pending') {
    logger.log({ msg: 'AuthRoute', to: p.waitQualificationEvidenceConfirmation });
    return <RedirectRoute {...props} to={p.waitQualificationEvidenceConfirmation} />;
  }

  // カウンセラーかつ資格情報登録済みかつ資格エビデンス登録がまだのユーザー
  if (
    privateUser.__typename === 'PrivateCounselor' &&
    qualificationRegistrationStatus(privateUser) === 'no_qualification'
  ) {
    logger.log({ msg: 'AuthRoute', to: p.registerQualificationEvidences });
    return <RedirectRoute {...props} to={p.registerQualificationEvidences} />;
  }

  // カウンセラーかつ Stripe の承認がまだのユーザ
  if (!targetIsPatient && (privateUser as PrivateCounselor).stripeCurrentDisabledReason === 'pending_verification') {
    logger.log({ msg: 'AuthRoute', to: p.waitStripeAccountConfirmation });
    return <RedirectRoute {...props} to={p.waitStripeAccountConfirmation} />;
  }

  // カウンセラーかつプラン未作成またはカウンセリングルーム未参加のユーザー
  // if (user.__typename === 'PrivateCounselor' && (!user.plans || !user.counselingRoomId)) {
  //   return <RedirectRoute {...props} to={p.root} />;
  // }

  // ログイン済みでトップページを表示しようとしたとき
  if (props.path === p.root) {
    logger.log({ msg: 'AuthRoute', to: p.app });
    return <Redirect to={p.app} />;
  }

  logger.log({ msg: 'AuthRoute', pass: true, to: props.location?.pathname });
  return <Route {...props} />;
};

export default AuthRoute;
