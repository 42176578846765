import dayjs from 'dayjs';
import 'dayjs/locale/ja';

import { _db } from 'db';
import { targetIsPatient } from 'env';
import { Concern, MessageType, PrivateUserType, Reservation, UserType } from 'graphql/graphql-interfaces';
import { PartiallyPartial } from 'lib/utilityTypes';
import { initialConfig } from 'db/db';
import {
  AppConfigModel,
  MessageModel,
  NotificationModel,
  profileKey,
  ProfileModel,
  ReservationModel,
  RoomModel,
  UserModel,
} from 'db/models';
import CustomCrypto from 'lib/crypto';
import logger from 'lib/logger';
import { CognitoUser } from 'services/auth-service';
import { cleanJsonParse } from 'lib/utils';

const crypto = new CustomCrypto({
  iv: 'This is initialization vectors'.padStart(16).slice(-16),
  key: 'This is encryption keys'.padStart(32).slice(-32),
});

// User actions
const encryptUser = (user: UserType): UserModel => ({ id: user.id, user: crypto.encrypt(user) });
const decryptUser = (userModel: UserModel): UserType => {
  const userStr = crypto.decrypt(userModel.user);
  return cleanJsonParse(userStr) as UserType;
};
export const dbPutUser = async (user: UserType) => _db.users.put(encryptUser(user));
export const dbGetUser = async (id: string) => {
  const userModel = await _db.users.get(id);
  if (userModel) return decryptUser(userModel);
  else {
    logger.warn('user not found in db');
    return null;
  }
};
export const dbGetAllUser = async () => {
  const users = await _db.users.toArray();
  if (users) {
    return users.map((user) => {
      return decryptUser(user);
    });
  } else {
    logger.warn('user not found in db');
    return null;
  }
};

// Message actions
const encryptMessage = (message: MessageType): MessageModel => {
  return { ...message, text: crypto.encrypt(message.text) };
};
const decryptMessage = (encryptedMessage: MessageModel): MessageType => {
  const message = { ...encryptedMessage, text: crypto.decrypt(encryptedMessage.text) };
  return message as MessageType;
};
/** Put a message without updating the room */
export const dbPutMessage = async (message: MessageType) => {
  return _db.messages.put(encryptMessage(message));
};
export const dbGetMessage = async (id: string) => {
  const encryptedMessage = await _db.messages.get(id);
  if (encryptedMessage) return decryptMessage(encryptedMessage);
  else {
    logger.error('message not found in db');
    return null;
  }
};
export const dbGetLatestMessage = async () => {
  const encryptedLatestMessage = await _db.messages.orderBy('timestamp').first();
  if (encryptedLatestMessage) return decryptMessage(encryptedLatestMessage);
  else logger.error('no messages in db');
};
export const dbGetOldestMessage = async () => {
  const encryptedLatestMessage = await _db.messages.orderBy('timestamp').last();
  if (encryptedLatestMessage) return decryptMessage(encryptedLatestMessage);
  else logger.error('no messages in db');
};

export const getTalkMessages = (roomId: string) =>
  _db.messages
    .where('fromId')
    .equals(roomId)
    .or('destId')
    .equals(roomId)
    .sortBy('timestamp')
    .then((messages) => messages.reverse().map(decryptMessage));
export const getTimeline = () =>
  _db.messages
    .where('__typename')
    .equals('Concern')
    .sortBy('timestamp')
    .then((concerns) =>
      concerns
        .map(decryptMessage)
        .filter((c): c is Concern => c.__typename === 'Concern')
        .sort((l, r) => l.timestamp - r.timestamp)
    );

// Room actions
export interface RoomModelRaw extends Omit<RoomModel, 'lastMessage' | 'reservation'> {
  lastMessage?: MessageType;
  reservation?: Reservation;
}
const encryptRoom = (roomRaw: RoomModelRaw): RoomModel => {
  const lastMessage = roomRaw.lastMessage ? encryptMessage(roomRaw.lastMessage) : undefined;
  const reservation = roomRaw.reservation ? encryptReservation(roomRaw.reservation) : undefined;
  const room: RoomModel = { ...roomRaw, lastMessage, reservation };
  return room;
};
const decryptRoom = (room: RoomModel): RoomModelRaw => {
  const lastMessage = room.lastMessage ? decryptMessage(room.lastMessage) : undefined;
  const reservation = room.reservation ? decryptReservation(room.reservation) : undefined;
  const roomRaw = { ...room, lastMessage, reservation };
  return roomRaw;
};
const putRoom = async (roomRaw: RoomModelRaw) => {
  if (roomRaw.lastMessage || roomRaw.reservation) {
    return _db.rooms.put(encryptRoom(roomRaw));
  }
};
const getRoom = async (roomId: string) => {
  const roomRaw = await _db.rooms.get(roomId);
  if (roomRaw) {
    return decryptRoom(roomRaw);
  }
};
export const updateRoom = async (roomId: string, message: MessageType) => {
  const room = await getRoom(roomId);
  if (!room || !room.lastMessage) {
    return putRoom({ id: roomId, lastMessage: message });
  } else if (message.timestamp > room.lastMessage.timestamp) {
    return putRoom({ ...room, lastMessage: message });
  }
};
export const dbGetInbox = async () => {
  const pickLatestTimestamp = (r: RoomModelRaw) => {
    const t1 = r.lastMessage?.timestamp;
    const t2 = r.reservation?.startTimestamp;
    if (t1 && !t2) return t1;
    if (t2 && !t1) return t2;
    if (t1 && t2) return Math.max(t1, t2);
    throw new Error('Invalid room' + r);
  };
  const compare = (a: RoomModelRaw, b: RoomModelRaw) => {
    return pickLatestTimestamp(a) - pickLatestTimestamp(b);
  };
  const check = (r: RoomModelRaw) => {
    const ts = dayjs().unix();
    if (r.reservation && r.reservation.endTimestamp < ts) {
      const room = { ...r, reservation: undefined };
      putRoom(room);
      return room;
    }
    return r;
  };
  const rooms = await _db.rooms.toArray().then((rooms) => rooms.map(decryptRoom));

  return rooms.map(check).sort(compare).reverse();
};

// Reservation actions
const encryptReservation = (reservation: Reservation): ReservationModel => {
  return { id: reservation.id, timestamp: reservation.endTimestamp, data: crypto.encrypt(reservation) };
};
const decryptReservation = (encryptedReservation: ReservationModel): Reservation => {
  const reservation = cleanJsonParse(crypto.decrypt(encryptedReservation.data)) as Reservation;
  return reservation;
};
const pickNextReservation = (reservations: Reservation[]) => {
  const nowTimestamp = dayjs().unix();
  let futureReservation: Reservation | undefined;
  for (const reservation of reservations) {
    if (!reservation.serviceStatus.startsWith('cancel') && reservation.endTimestamp > nowTimestamp) {
      if (!futureReservation) futureReservation = reservation;
      else if (reservation.startTimestamp < futureReservation.startTimestamp) futureReservation = reservation;
    }
  }
  return futureReservation;
};

export const putReservation = async (reservationRaw: Reservation) => {
  _db.reservations.put(encryptReservation(reservationRaw));

  const roomId = targetIsPatient ? reservationRaw.counselorId : reservationRaw.patientId;
  const room = await getRoom(roomId);
  if (!room) {
    return putRoom({ id: roomId, reservation: reservationRaw });
  } else {
    const nextReservation = room.reservation ? pickNextReservation([reservationRaw, room.reservation]) : reservationRaw;
    return putRoom({ ...room, reservation: nextReservation });
  }
};
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const getReservation = async (reservationId: string) => {
  const reservation = await _db.reservations.get(reservationId);
  if (reservation) {
    return decryptReservation(reservation);
  }
};

export const getNextReservation = async (roomId: string) => {
  const reservations = await _db.reservations.toArray().then((r) => r.map(decryptReservation));
  const roomReservations = reservations.filter((r) => r.counselorId === roomId || r.patientId === roomId);
  const nextReservation = pickNextReservation(roomReservations);
  return nextReservation;
};

export const dbGetLatestReservation = async () => {
  const encryptedLatestReservation = await _db.reservations.orderBy('timestamp').first();
  if (encryptedLatestReservation) return decryptReservation(encryptedLatestReservation);
  else logger.error('no reservations in db');
};

export const dbGetOldestReservation = async () => {
  const encryptedLatestReservation = await _db.reservations.orderBy('timestamp').last();
  if (encryptedLatestReservation) return decryptReservation(encryptedLatestReservation);
  else logger.error('no reservations in db');
};

// Config actions
export const dbGetConfig = () =>
  _db.config
    .orderBy('id')
    .first()
    .then((config) => config ?? initialConfig);
export const dbUpdateConfig = async (diffConfig: Partial<AppConfigModel>) => {
  const currentConfig = await dbGetConfig();
  return _db.config.put({ ...currentConfig, ...diffConfig });
};

// Profile actions
export interface ProfileModelRaw {
  key: typeof profileKey;
  id: string;
  cognitoUser: CognitoUser;
  privateUser?: PrivateUserType;
}
type SetProfileProps = Omit<PartiallyPartial<ProfileModelRaw, 'privateUser'>, 'key'>;
const encryptProfile = (profileRaw: ProfileModelRaw): ProfileModel => {
  const encryptedCognitoUser = crypto.encrypt(profileRaw.cognitoUser);
  const encryptedPrivateUser = profileRaw.privateUser ? crypto.encrypt(profileRaw.privateUser) : undefined;
  const profile = { ...profileRaw, cognitoUser: encryptedCognitoUser, privateUser: encryptedPrivateUser };
  return profile;
};
const decryptProfile = (profile: ProfileModel): ProfileModelRaw => {
  const cognitoUserStr = crypto.decrypt(profile.cognitoUser);
  const cognitoUser = cleanJsonParse(cognitoUserStr) as CognitoUser;
  if (profile.privateUser) {
    const privateUserStr = crypto.decrypt(profile.privateUser);
    const privateUser = cleanJsonParse(privateUserStr) as PrivateUserType;
    const apiCache = { ...profile, cognitoUser, privateUser };
    return apiCache;
  } else {
    const apiCache = { ...profile, cognitoUser, privateUser: undefined };
    return apiCache;
  }
};
export const putProfile = async (profile: SetProfileProps) =>
  _db.profile.put(encryptProfile({ key: profileKey, ...profile }));
export const getProfile = () => {
  return _db.profile.get(profileKey).then((profile) => {
    if (profile) return decryptProfile(profile);
    else return null;
  });
};
export const clearProfile = async () => _db.profile.clear();

// Notification actions
export const putNotification = (notification: NotificationModel) => _db.notifications.put(notification);
export const getNotification = (id: string) => _db.notifications.get(id);
export const getNotifications = () => _db.notifications.orderBy('timestamp').toArray();

// Temp actions
export const putTemp = (key: string, data: string) => _db.temp.put({ key, data: crypto.encrypt(data) });
export const getTemp = (key: string) => _db.temp.get(key).then((temp) => (temp ? crypto.decrypt(temp.data) : null));
export const deleteTemp = (...keys: string[]) => keys.map((key) => _db.temp.delete(key));

// Entire tables
export const clearDb = async () => {
  _db.config.clear();
  _db.temp.clear();
  _db.users.clear();
  _db.rooms.clear();
  _db.messages.clear();
  _db.reservations.clear();
  _db.notifications.clear();
};

export const isValidDataOwner = async (userId: string): Promise<boolean> => {
  //TODO: room 以外の単純な処理でログインユーザーとキャッシュ内データの所持者の不一致を割り出せる Model についての条件式を処理が軽い順に記述する
  const isUserRoomOwner = (room: RoomModelRaw, userId: string) => {
    if (room.reservation) {
      if (targetIsPatient) {
        return room.reservation.patientId === userId;
      } else {
        return room.reservation.counselorId === userId;
      }
    }
    if (room.lastMessage) {
      return room.lastMessage.fromId === userId || room.lastMessage.destId === userId;
    }
    logger.warn('Invalid room is detected.');
    // 異常 room はデータの所持者とは関係がないため true を返す
    return true;
  };

  const rooms = await dbGetInbox();
  const unmatchedRooms = rooms.filter((room) => {
    return room && !isUserRoomOwner(room, userId);
  });
  if (unmatchedRooms.length) {
    logger.warn(`Authenticated user ${userId} is not owner of existing cached data.`);
    return false;
  }

  return true;
};
