import React, { useState, useContext, createContext, useCallback, useEffect } from 'react';

import { Alert, AlertColor, Slide, Stack, styled } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

interface SnackbarContext {
  enqueueSnackbar: EnqueueSnackbar;
}
const snackbarContext = createContext<SnackbarContext>({
  enqueueSnackbar: () => void 0,
});

interface SnackbarProviderProps {
  max: number;
}

interface CustomSnackbarProps {
  variant: AlertColor;
  message: string;
  autoHideDuration?: number;
  handleClose: () => void;
}

const CustomSnackbar = React.memo<CustomSnackbarProps>((props) => {
  const { variant, message, autoHideDuration, handleClose } = props;

  const hideMilliSeconds = autoHideDuration ?? 5000;
  useEffect(() => {
    setTimeout(handleClose, hideMilliSeconds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Slide direction="right" in mountOnEnter unmountOnExit>
      <Alert onClose={handleClose} severity={variant} variant="filled">
        {message}
      </Alert>
    </Slide>
  );
});

const OverrideStack = styled(Stack)`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0.5rem;
  zindex: 1000;
  display: flex;
`;

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ max, children }) => {
  const { snackbarStack, enqueueSnackbar, handleCloseSnackbar } = useProvideSnackbar();
  return (
    <snackbarContext.Provider value={{ enqueueSnackbar }}>
      <OverrideStack spacing={1} direction="column-reverse" sx={{ zIndex: 2000 }}>
        {snackbarStack.slice(0, max).map(([id, props]) => (
          <CustomSnackbar {...props} key={id} handleClose={handleCloseSnackbar(id)} />
        ))}
      </OverrideStack>
      {children}
    </snackbarContext.Provider>
  );
};

type EnqueueSnackbarProps = Omit<CustomSnackbarProps, 'handleClose'>;
type EnqueueSnackbar = (_props: EnqueueSnackbarProps) => void;

const useProvideSnackbar = () => {
  const [snackbarStack, setSnackbarStack] = useState<[string, EnqueueSnackbarProps][]>([]);

  const enqueueSnackbar: EnqueueSnackbar = (props) => {
    const id = uuidv4();
    setSnackbarStack([...snackbarStack, [id, props]]);
  };

  const handleCloseSnackbar = useCallback(
    (targetId: string) => () => setSnackbarStack((stack) => stack.filter(([id, _Component]) => id !== targetId)),
    []
  );

  return { snackbarStack, enqueueSnackbar, handleCloseSnackbar };
};

export const useSnackbar = () => useContext(snackbarContext);

export default SnackbarProvider;
