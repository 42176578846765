const STAGE = process.env.NODE_ENV;
const ENV_STAGE = process.env.REACT_APP_STAGE;
const stage = ENV_STAGE || STAGE;
/** ステージ判定を行う
 *  デフォルトだと development
 *  指定する場合は ENV_STAGE 環境変数に指定して、 `src/assets/awsConfig.${ENV_STAGE}.json` を用意する
 */
const checkStage = () => {
  if (stage === 'production') {
    // logger.log('current stage: ' + stage);
    return stage;
  }
  if (stage === 'development') {
    console.log('current stage: ' + stage);
    return stage;
  }
  if (stage === 'test') {
    console.log('current stage: ' + stage);
    return stage;
  }
  // fallback
  console.log('current stage: ' + stage);
  return stage || 'test';
};

export default checkStage;
