import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';

import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import GraphQlProvider from 'components/GraphQlProvider';
import SWUpdateDialog from 'components/SWUpdateDialog';
import SnackbarProvider from 'components/SnackbarProvider';
import { beforeInstallPromptEventListener } from 'lib/installPromptEvent';

import { register } from 'serviceWorkerRegistration';
import App from 'App';
import OmamoriThemeProvider from 'components/OmamoriThemeProvider';

import { ThemeProvider } from 'styled-components';
import { MeetingProvider, lightTheme } from 'amazon-chime-sdk-component-library-react';

const Root = () => (
  <React.StrictMode>
    <div className="SW-update-dialog" />
    <Router>
      <Suspense fallback={<div>loading</div>}>
        <OmamoriThemeProvider>
          <LocalizationProvider dateAdapter={DateAdapter} locale="ja">
            <GraphQlProvider />
            <SnackbarProvider max={3}>
              <ThemeProvider theme={lightTheme}>
                <MeetingProvider>
                  <App />
                </MeetingProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </OmamoriThemeProvider>
      </Suspense>
    </Router>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById('root'));

window.addEventListener('beforeinstallprompt', beforeInstallPromptEventListener);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      ReactDOM.render(<SWUpdateDialog registration={registration} />, document.querySelector('.SW-update-dialog'));
    }
  },
});
