import { PaletteColorOptions } from '@mui/material';

const targetGroup = process.env.REACT_APP_TARGET ?? 'patient';
export const isProduction = process.env.NODE_ENV === 'production';
export const targetIsPatient = targetGroup === 'patient';
export const group = targetIsPatient ? 'patients' : 'counselors';
export const mainColorPalette: PaletteColorOptions = targetIsPatient
  ? {
      main: '#F08317',
      contrastText: '#FFFFFF',
      light: '#FCE6CF',
    }
  : {
      main: '#165E83',
      contrastText: '#FFFFFF',
      light: '#D3ECF8',
    };
