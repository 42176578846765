import crypro from 'crypto';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

export function timeout(msec: number) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}
export function uniqueArray<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

/**
 * 電話番号をグローバル表記(+81...)に変換
 */
export function convertPhoneNumberToGlobal(phoneNumber: string) {
  return /^0\d{10}$/.test(phoneNumber) ? '+81' + phoneNumber.slice(1) : phoneNumber;
}
/**
 * 電話番号をローカル表記(090...)に変換
 */
export function convertPhoneNumberToLocal(phoneNumber: string) {
  return /^\+81\d{10}$/.test(phoneNumber) ? '0' + phoneNumber.slice(3) : phoneNumber;
}

/**
 * 数字の左を 0 埋めする
 */
export function zeroPadding(num: number, len: number) {
  return (Array(len).join('0') + num).slice(-len);
}

/**
 * 完全な URL を取得する
 * @param pathname PagePaths.home
 * @returns https://{current origin}/app
 */
export function absoluteUrl(pathname: string) {
  return window.location.origin + pathname;
}

/**
 * 文字列がひらがなか判定する
 */
export function isHiragana(str: string) {
  return /^[\u3040-\u309F]+$/.test(str);
}

/**
 * 文字列が全角カタカナか判定する
 */
export function isZenkakuKana(str: string) {
  return /^[\u30A0-\u30FF]+$/.test(str);
}

/**
 * 文字列が半角カタカナか判定する
 */
export function isHankakuKana(str: string) {
  return /^[\uFF61-\uFF9F]+$/.test(str);
}

/**
 * 文字列が郵便番号の形式が判定する
 */
export function isValidZipCode(zipCode: string) {
  return /^\d{7}$/.test(zipCode);
}

export function hash(data: string) {
  return crypro.createHash('md5').update(data).digest('hex');
}

const cleaner = (key: string, value: unknown) => (key === '__proto__' ? undefined : value);

/** prototype 継承させずに JSON.parse する */
export function cleanJsonParse(data: string) {
  return JSON.parse(data, cleaner);
}

/**
 * 日付のみのタイムスタンプを取得
 */
export const getDateTimestamp = (ts: number) => dayjs(dayjs.unix(ts).format('YYYY/MM/DD'), 'YYYY/MM/DD').unix();

/**
 * タイムスタンプを`時:分`に変換
 * 24:00 を超えて表示できる
 */
export const timestamp2time = (ts: number) => {
  const seconds = ts % 60;
  ts = (ts - seconds) / 60;
  const minutes = ts % 60;
  const hours = (ts - minutes) / 60;
  return `${zeroPadding(hours, 2)}:${zeroPadding(minutes, 2)}`;
};
