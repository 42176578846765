import { dbGetInbox, dbGetUser, dbPutUser } from 'db';
import API from 'services/graphql-service';
import logger from 'lib/logger';
import { UserType } from 'graphql/graphql-interfaces';

export const getUser = async (id: string) => {
  try {
    const gqlUser = await API._getUser({ id });
    if (gqlUser) {
      dbPutUser(gqlUser);
      return gqlUser;
    }
    const dbUser = await dbGetUser(id);
    if (dbUser) return dbUser;

    logger.log('User not found', { id });
    return null;
  } catch (error) {
    logger.error(error, { id });
    return null;
  }
};
export const putUser = async (user: UserType) => {
  try {
    await dbPutUser(user);
    return;
  } catch (error) {
    logger.error(error);
    return;
  }
};
export const refreshUser = async (id: string) => getUser(id);

export const getAllUserIds = async () => {
  const rooms = await dbGetInbox();
  return rooms.map((room) => room.id);
};
