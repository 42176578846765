import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { BottomNavigation, BottomNavigationAction, Box, Typography } from '@mui/material';

import AppsIcon from '@mui/icons-material/Apps';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import { targetIsPatient } from 'env';

import Search from 'pages/app/Search';
import Timeline from 'pages/app/Timeline';
import Inbox from 'pages/app/Inbox';
import Notification from 'pages/app/Notification';
import Mypage from 'pages/app/Mypage';
import PagePaths from 'pagepaths';
import AppPage from 'layouts/AppPage';

interface TabContent {
  pathname: string;
  content: React.ReactElement;
  label: string;
  icon: React.ReactElement;
}
const tabContents: TabContent[] = [
  targetIsPatient
    ? {
        pathname: PagePaths.search,
        content: <Search />,
        label: 'さがす',
        icon: <SearchRoundedIcon />,
      }
    : {
        pathname: PagePaths.timeline,
        content: <Timeline />,
        label: 'タイムライン',
        icon: <AppsIcon />,
      },
  {
    pathname: PagePaths.inbox,
    content: <Inbox />,
    label: '相談室',
    icon: <ChatBubbleOutlineRoundedIcon />,
  },
  {
    pathname: PagePaths.notification,
    content: <Notification />,
    label: 'お知らせ',
    icon: <NotificationsRoundedIcon />,
  },
  {
    pathname: PagePaths.mypage,
    content: <Mypage />,
    label: 'マイページ',
    icon: <PersonRoundedIcon />,
  },
];

const Home: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (_: unknown, index: number) => handleChangeIndex(index);
  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
    history.replace(tabContents[index].pathname);
  };

  tabContents.forEach((content, index) => {
    if (content.pathname === location.pathname && index !== tabIndex) {
      setTabIndex(index);
    }
  });

  return (
    <>
      <AppPage title={tabContents[tabIndex].label} disableBackButton containerSx={{ paddingBottom: 10 }}>
        {tabContents[tabIndex].content}
      </AppPage>
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <BottomNavigation value={tabIndex} onChange={handleChange} showLabels={true}>
          {tabContents.map((tabContent, i) => (
            <BottomNavigationAction
              key={`navitem_${i}`}
              icon={tabContent.icon}
              label={
                <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                  {tabContent.label}
                </Typography>
              }
            />
          ))}
        </BottomNavigation>
      </Box>
    </>
  );
};

export default Home;
