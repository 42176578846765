import { GraphQLAPI, graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { Observable } from 'zen-observable-ts';

import * as Query from 'graphql/queries';
import * as Mutation from 'graphql/mutations';
import * as Subscription from 'graphql/subscriptions';
import * as GqlInterfaces from 'graphql/graphql-interfaces';

import loadAwsConfig from 'awsConfig';
import logger from 'lib/logger';
loadAwsConfig();

interface CallProps<P> {
  query: string;
  props: P;
}
async function call<P, R>({ query, props }: CallProps<P>): Promise<{ data?: R }> {
  const res = (await GraphQLAPI.graphql(graphqlOperation(query, props))) as GraphQLResult<R>;

  if (res.errors) {
    logger.error(res.errors.toString());
  }
  if (!res.data) {
    logger.error('response data is empty');
  }
  if (res.data) {
    logger.log(res.data);
  }

  return { data: res.data };
}

class GraphQLService {
  async _getUser(props: GqlInterfaces.GetUserProps) {
    const { data } = await call<typeof props, GqlInterfaces.GetUserResponse>({ query: Query.getUser, props });
    return data?.getUser;
  }

  async getMessage(props: GqlInterfaces.GetMessageProps) {
    const { data } = await call<typeof props, GqlInterfaces.GetMessageResponse>({ query: Query.getMessage, props });
    return data?.getMessage;
  }

  async createUser(props: GqlInterfaces.CreateUserProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreateUserResponse>({ query: Mutation.createUser, props });
    return data?.createUser;
  }

  async getPlan(props: GqlInterfaces.GetPlanProps) {
    const { data } = await call<typeof props, GqlInterfaces.GetPlanResponse>({ query: Query.getPlan, props });
    return data?.getPlan;
  }

  async getCounselorPlans(props: GqlInterfaces.GetCounselorPlansProps) {
    const { data } = await call<typeof props, GqlInterfaces.GetCounselorPlansResponse>({
      query: Query.getCounselorPlans,
      props,
    });
    return data?.getCounselorPlans;
  }

  async createPlan(props: GqlInterfaces.CreatePlanProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreatePlanResponse>({ query: Mutation.createPlan, props });
    return data?.createPlan;
  }

  async updatePlan(props: GqlInterfaces.UpdatePlanProps) {
    const { data } = await call<typeof props, GqlInterfaces.UpdatePlanResponse>({ query: Mutation.updatePlan, props });
    return data?.updatePlan;
  }

  async createAvailableTime(props: GqlInterfaces.CreateAvailableTimeProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreateAvailableTimeResponse>({
      query: Mutation.createAvailableTime,
      props,
    });
    return data?.createAvailableTime;
  }

  async deleteAvailableTime(props: GqlInterfaces.DeleteAvailableTimeProps) {
    const { data } = await call<typeof props, GqlInterfaces.DeleteAvailableTimeResponse>({
      query: Mutation.deleteAvailableTime,
      props,
    });
    return data?.deleteAvailableTime;
  }

  async cancelReservation(props: GqlInterfaces.CancelReservationProps) {
    const { data } = await call<typeof props, GqlInterfaces.CancelReservationResponse>({
      query: Mutation.cancelReservation,
      props,
    });
    return data?.cancelReservation;
  }

  async getConcerns(props: GqlInterfaces.GetConcernsProps) {
    const { data } = await call<typeof props, GqlInterfaces.GetConcernsResponse>({ query: Query.getConcerns, props });
    return data?.getConcerns;
  }

  async listCounselors(props: GqlInterfaces.ListCounselorsProps) {
    const { data } = await call<typeof props, GqlInterfaces.ListCounselorsResponse>({
      query: Query.listCounselors,
      props,
    });
    return data?.listCounselors;
  }

  async listCounselorCalendarItems(props: GqlInterfaces.ListCounselorCalendarItemsProps) {
    const { data } = await call<typeof props, GqlInterfaces.ListCounselorCalendarItemsResponse>({
      query: Query.listCounselorCalendarItems,
      props,
    });
    return data?.listCounselorCalendarItems.calendarItems;
  }

  async listCounselorReservations(props: GqlInterfaces.listCounselorReservationsProps) {
    const { data } = await call<typeof props, GqlInterfaces.listCounselorReservationsResponse>({
      query: Query.listCounselorReservations,
      props,
    });
    return data?.listCounselorReservations.reservations;
  }

  async listCounselorAvailableTimes(props: GqlInterfaces.ListCounselorAvailableTimesProps) {
    const { data } = await call<typeof props, GqlInterfaces.ListCounselorAvailableTimesResponse>({
      query: Query.listCounselorAvailableTimes,
      props,
    });
    return data?.listCounselorAvailableTimes.availableTimes;
  }

  async listSelfCalendarItems(props: GqlInterfaces.ListSelfCalendarItemsProps) {
    const { data } = await call<typeof props, GqlInterfaces.ListSelfCalendarItemsResponse>({
      query: Query.listSelfCalendarItems,
      props,
    });
    return data?.listSelfCalendarItems.calendarItems;
  }

  async listSelfReservations(props: GqlInterfaces.ListSelfReservationsProps) {
    const { data } = await call<typeof props, GqlInterfaces.ListSelfReservationsResponse>({
      query: Query.listSelfReservations,
      props,
    });
    return data?.listSelfReservations.reservations;
  }

  async postComment(props: GqlInterfaces.PostCommentProps) {
    const convertedProps = {
      ...props,
      attachments: JSON.stringify(props.attachments),
    };
    const { data } = await call<typeof convertedProps, GqlInterfaces.PostCommentResponse>({
      query: Mutation.postComment,
      props: convertedProps,
    });
    return data?.postComment;
  }

  async postConcern(props: GqlInterfaces.PostConcernProps) {
    const convertedProps = {
      ...props,
      attachments: JSON.stringify(props.attachments),
    };
    const { data } = await call<typeof convertedProps, GqlInterfaces.PostConcernResponse>({
      query: Mutation.postConcern,
      props: convertedProps,
    });
    return data?.postConcern;
  }

  async postChat(props: GqlInterfaces.PostChatProps) {
    const convertedProps = {
      ...props,
      attachments: JSON.stringify(props.attachments),
    };
    const { data } = await call<typeof convertedProps, GqlInterfaces.PostChatResponse>({
      query: Mutation.postChat,
      props: convertedProps,
    });
    return { chat: data?.postChat };
  }

  async updatePatient(props: GqlInterfaces.UpdatePatientProps) {
    const { data } = await call<typeof props, GqlInterfaces.UpdatePatientResponse>({
      query: Mutation.updatePatient,
      props,
    });
    return data?.updatePatient;
  }

  async updateCounselor(props: GqlInterfaces.UpdateCounselorProps) {
    const { data } = await call<typeof props, GqlInterfaces.UpdateCounselorResponse>({
      query: Mutation.updateCounselor,
      props,
    });
    return data?.updateCounselor;
  }

  async createPaymentAccount(props: GqlInterfaces.CreatePaymentAccountProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreatePaymentAccountResponse>({
      query: Mutation.createPaymentAccount,
      props,
    });
    return data?.createPaymentAccount;
  }

  async createPutObjectPresignedUrl(props: GqlInterfaces.CreatePutObjectPresignedUrlProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreatePutObjectPresignedUrlResponse>({
      query: Query.createPutObjectPresignedUrl,
      props,
    });
    const url = data?.createPutObjectPresignedUrl?.presignedUrl;
    if (!url) throw new Error('presignedUrl was not returned.');
    return url;
  }

  async postedChat(props: GqlInterfaces.PostedChatProps, callback: (_message?: GqlInterfaces.Chat) => void) {
    const connect = GraphQLAPI.graphql(
      graphqlOperation(Subscription.postedChat, props)
    ) as Observable<GqlInterfaces.PostedChatResponse>;
    return connect.subscribe((res) => {
      if (res.value.errors) {
        logger.error(res.value.errors.toString());
      }
      if (!res.value.data || !res.value.data?.postedChat) {
        logger.error('response data is empty');
      }
      callback(res.value.data?.postedChat);
    }, logger.error);
  }

  async createCounselingRoom(props: GqlInterfaces.CreateCounselingRoomProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreateCounselingRoomResponse>({
      query: Mutation.createCounselingRoom,
      props,
    });
    return data?.createCounselingRoom;
  }

  async updateCounselingRoom(props: GqlInterfaces.UpdateCounselingRoomProps) {
    const { data } = await call<typeof props, GqlInterfaces.UpdateCounselingRoomResponse>({
      query: Mutation.updateCounselingRoom,
      props,
    });
    return data?.updateCounselingRoom;
  }

  async createCounselingJwt(props: GqlInterfaces.CreateCounselingJwtProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreateCounselingJwtResponse>({
      query: Query.createCounselingJwt,
      props,
    });
    return data?.createCounselingJwt.token;
  }

  async createPaymentAccountOnboardingUrl(props: GqlInterfaces.CreatePaymentAccountOnboardingUrlProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreatePaymentAccountOnboardingUrlResponse>({
      query: Query.createPaymentAccountOnboardingUrl,
      props,
    });
    return data?.createPaymentAccountOnboardingUrl.url;
  }

  async createPaymentSession(props: GqlInterfaces.CreatePaymentSessionProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreatePaymentSessionResponse>({
      query: Query.createPaymentSession,
      props,
    });
    return data?.createPaymentSession;
  }

  async getCounselingRoom(props: GqlInterfaces.GetCounselingRoomProps) {
    const { data } = await call<typeof props, GqlInterfaces.GetCounselingRoomResponse>({
      query: Query.getCounselingRoom,
      props,
    });
    return data?.counselingRoom;
  }

  async getReservation(props: GqlInterfaces.GetReservationProps) {
    const { data } = await call<typeof props, GqlInterfaces.GetReservationResponse>({
      query: Query.getReservation,
      props,
    });
    return data?.getReservation;
  }

  async updatePaymentAccount(props: GqlInterfaces.UpdatePaymentAccountProps) {
    const { data } = await call<typeof props, GqlInterfaces.UpdatePaymentAccountResponse>({
      query: Query.updatePaymentAccount,
      props,
    });
    return data?.stripeId;
  }

  async searchCounselors(props: GqlInterfaces.SearchCounselorsProps) {
    const { data } = await call<typeof props, GqlInterfaces.SearchCounselorsResponse>({
      query: Query.searchCounselors,
      props,
    });
    return data?.searchCounselors;
  }

  // Video APIs
  async createVideoMeeting(props: GqlInterfaces.CreateVideoMeetingProps) {
    const { data } = await call<typeof props, GqlInterfaces.CreateVideoMeetingResponse>({
      query: Mutation.createVideoMeeting,
      props,
    });
    if (!data) return null;
    return {
      meeting: JSON.parse(data.createVideoMeeting.meeting) as Record<string, string>,
      attendee: JSON.parse(data.createVideoMeeting.attendee) as Record<string, string>,
    };
  }
  async attendVideoMeeting(props: GqlInterfaces.AttendVideoMeetingProps) {
    const { data } = await call<typeof props, GqlInterfaces.AttendVideoMeetingResponse>({
      query: Mutation.attendVideoMeeting,
      props,
    });
    if (!data) return null;
    return {
      meeting: JSON.parse(data.attendVideoMeeting.meeting) as Record<string, string>,
      attendee: JSON.parse(data.attendVideoMeeting.attendee) as Record<string, string>,
    };
  }

  private async syncIncomingMessages(props: GqlInterfaces.SyncIncomingMessagesProps) {
    const { data } = await call<typeof props, GqlInterfaces.SyncIncomingMessagesResponse>({
      query: Query.syncIncomingMessages,
      props,
    });
    return data?.syncIncomingMessages;
  }

  private async syncOutgoingMessages(props: GqlInterfaces.SyncOutgoingMessagesProps) {
    const { data } = await call<typeof props, GqlInterfaces.SyncOutgoingMessagesResponse>({
      query: Query.syncOutgoingMessages,
      props,
    });
    return data?.syncOutgoingMessages;
  }

  // Custom APIs
  async syncIncomingMessagesAll(props: GqlInterfaces.SyncIncomingMessagesProps) {
    const messages = new Array<GqlInterfaces.MessageType>();
    let nextToken: string | undefined;
    do {
      const res = await this.syncIncomingMessages({ ...props, nextToken });
      if (res) {
        messages.push(...res.messages);
        nextToken = res.nextToken ?? undefined;
      } else {
        break;
      }
    } while (nextToken);
    return messages;
  }

  async syncOutgoingMessagesAll(props: GqlInterfaces.SyncOutgoingMessagesProps) {
    const messages = new Array<GqlInterfaces.MessageType>();
    let nextToken: string | undefined;
    do {
      const res = await this.syncOutgoingMessages({ ...props, nextToken });
      if (res) {
        messages.push(...res.messages);
        nextToken = res.nextToken ?? undefined;
      } else {
        break;
      }
    } while (nextToken);
    return messages;
  }
}

const API = new GraphQLService();
export default API;
