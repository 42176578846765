export const postedChat = `
subscription ($destId: String!) {
  postedChat (destId: $destId) {
    __typename
    id
    fromId
    destId
    timestamp
    text
  }
}`;
