import { calendarItemFragment, publicCalendarItemFragment, chatFragment, userFragment } from 'graphql/fragments';

export const getUser = `
query ($id: String!){
  getUser(id: $id) {
    ${userFragment}
  }
}`;

export const getMessage = `
query ($messageId: String!){
  getMessage(messageId: $messageId) {
    __typename
    id
    fromId
    destId
    timestamp
    ${chatFragment}
  }
}`;

export const getConcerns = `
query ($limit: Int!, $nextToken: String){
  getConcerns(limit: $limit, nextToken: $nextToken) {
    messages{
        __typename
        id
        fromId
        destId
        timestamp
        ${chatFragment}
    }
    nextToken
  }
}`;

export const listCounselors = `
query ($limit: Int!, $nextToken: String){
  listCounselors(limit: $limit, nextToken: $nextToken) {
      users {
        ${userFragment}
      }
      nextToken
  }
}`;

export const createPutObjectPresignedUrl = `
query ($objectType: String!, $contentType: String!){
  createPutObjectPresignedUrl(objectType: $objectType, contentType: $contentType){
    presignedUrl
  }
}`;

export const getPlan = `
query ($planId: String!){
  getPlan(planId: $planId){
    id
    serviceGroup
    serviceType
    serviceUnit

    minutes
    price
    stripeProductId
    stripePriceId

    name
    description

    active
  }
}`;

export const getCounselorPlans = `
query ($counselorId: String!){
  getCounselorPlans(counselorId: $counselorId){
    plans {
      id
      serviceGroup
      serviceType
      serviceUnit

      minutes
      price
      stripeProductId
      stripePriceId

      name
      description

      active
    }
  }
}`;

export const listCounselorCalendarItems = `
query ($limit: Int!, $counselorId: String!, $startTimestamp: Int!, $endTimestamp: Int){
  listCounselorCalendarItems(limit: $limit, counselorId: $counselorId, startTimestamp: $startTimestamp, endTimestamp: $endTimestamp){
    calendarItems{
      ${publicCalendarItemFragment}
    }
  }
}`;

export const listCounselorReservations = `
query ($limit: Int!, $counselorId: String!, $startTimestamp: Int!, $endTimestamp: Int){
  listCounselorReservations(limit: $limit, counselorId: $counselorId, startTimestamp: $startTimestamp, endTimestamp: $endTimestamp){
    reservations{
      ${publicCalendarItemFragment}
    }
  }
}`;

export const listCounselorAvailableTimes = `
query ($limit: Int!, $counselorId: String!, $startTimestamp: Int!, $endTimestamp: Int){
  listCounselorAvailableTimes(limit: $limit, counselorId: $counselorId, startTimestamp: $startTimestamp, endTimestamp: $endTimestamp){
    availableTimes{
      ${publicCalendarItemFragment}
    }
  }
}`;

export const listSelfCalendarItems = `
query ($limit: Int!, $startTimestamp: Int!, $endTimestamp: Int){
  listSelfCalendarItems(limit: $limit, startTimestamp: $startTimestamp, endTimestamp: $endTimestamp){
    calendarItems{
      ${calendarItemFragment}
    }
  }
}`;

export const listSelfReservations = `
query ($limit: Int!, $startTimestamp: Int!, $endTimestamp: Int){
  listSelfReservations(limit: $limit, startTimestamp: $startTimestamp, endTimestamp: $endTimestamp){
    reservations{
      ${calendarItemFragment}
    }
  }
}`;

export const createCounselingJwt = `
query ($destId: String!){
  createCounselingJwt(destId: $destId){
    token
  }
}`;

export const createPaymentAccountOnboardingUrl = `
query ($successUrl: String!, $failureUrl: String!, $mode: String!){
  createPaymentAccountOnboardingUrl(successUrl: $successUrl, failureUrl: $failureUrl, mode: $mode){
    url
  }
}`;

export const createPaymentSession = `
query (
  $counselorId: String!,
  $successUrl: String!,
  $cancelUrl: String!,
  $planId: String!,
  $startTimestamp: Int!,
  $counselingMajorClassification: String!,
  $counselingMinorClassification: String,
  $counselingContent: String,
  $counselingComment: String
){
  createPaymentSession(
    counselorId: $counselorId
    successUrl: $successUrl
    cancelUrl: $cancelUrl
    planId: $planId
    startTimestamp: $startTimestamp
    counselingMajorClassification: $counselingMajorClassification
    counselingMinorClassification: $counselingMinorClassification
    counselingContent: $counselingContent
    counselingComment: $counselingComment
  ){
    url
    doubleCounseling
  }
}`;

export const getCounselingRoom = `
query ($counselingRoomId: String!){
  getCounselingRoom(counselingRoomId: $counselingRoomId){
    id
    adminCounselor
    name
    plans
    description
    address
    phoneNumber
    profileImage
    stripeId

    createdTimestamp
    updatedTimestamp
    active
  }
}`;

export const getReservation = `
query ($reservationId: String!){
  getReservation(reservationId: $reservationId){
    id
    serviceGroup
    serviceType
    serviceUnit

    planId
    price
    omamoriPlatformFee
    startTimestamp
    endTimestamp

    counselingMajorClassification
    counselingMinorClassification
    counselingContent
    counselingComment

    reservedTimestamp
    chargeId
    serviceStatus
    counselingRoomId
    patientId
    counselorId

    transferId

    cancelRequestTimestamp
    refundId
    refundCompleteTimestamp
    refundPrice
    counselorChargeId
    counselorChargeCompleteTimestamp
    counselorChargePrice
    cancelReason
  }
}`;

export const listPatientCalendarItem = `
query ($limit: Int!, $startTimestamp: Int!, $patientId: String){
  listPatientCalendarItem(limit: $limit, startTimestamp: $startTimestamp, patientId: $patientId){
    calendarItems{
      ${calendarItemFragment}
    }
  }
}`;

export const updatePaymentAccount = `
query ($accessToken: String!, $accountType: String!, $ip: String!){
  updatePaymentAccount(accessToken: $accessToken, accountType: $accountType, ip: $ip){
    stripeId
  }
}`;

export const searchCounselors = `
query ($limit: Int!, $query: String, $expert: String){
  searchCounselors(limit: $limit, query: $query, expert: $expert){
    users {
      ${userFragment}
    }
    nextToken
  }
}`;

export const syncIncomingMessages = `
query ($limit: Int!, $lastSync: Int!, $nextToken: String, $fromId: String){
  syncIncomingMessages(limit: $limit, lastSync: $lastSync, nextToken: $nextToken, fromId: $fromId){
    messages{
      __typename
      id
      fromId
      destId
      timestamp
      ${chatFragment}
    }
    nextToken
  }
}`;

export const syncOutgoingMessages = `
query ($limit: Int!, $lastSync: Int!, $nextToken: String, $destId: String){
  syncOutgoingMessages(limit: $limit, lastSync: $lastSync, nextToken: $nextToken, destId: $destId){
    messages{
      __typename
      id
      fromId
      destId
      timestamp
      ${chatFragment}
    }
    nextToken
  }
}`;
