import React from 'react';

import { pink } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

import { mainColorPalette } from 'env';

const OmamoriThemeProvider: React.FC = (props) => {
  const theme = responsiveFontSizes(
    createTheme({
      palette: {
        primary: mainColorPalette,
        secondary: pink,
      },
      typography: {
        fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            '::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '::-webkit-scrollbar-track': {
              borderRadius: '10px',
              boxShadow: 'inset 0 0 6px rgba(0, 0, 0, .1)',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, .2)',
              borderRadius: '10px',
              boxShadow: '0 0 0 1px rgba(255, 255, 255, .3)',
            },
            '::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(0, 0, 0, .4)',
            },
          },
        },
      },
    })
  );
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};

export default OmamoriThemeProvider;
