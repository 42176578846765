import { dbGetLatestMessage, dbGetMessage, dbPutMessage, updateRoom } from 'db';
import API from 'services/graphql-service';
import logger from 'lib/logger';
import { MessageType, PostChatProps, PostCommentProps, PostConcernProps } from 'graphql/graphql-interfaces';

export const getMessage = async (id: string) => {
  try {
    const dbMessage = await dbGetMessage(id);
    if (dbMessage) return dbMessage;
    const gqlMessage = await API.getMessage({ messageId: id });
    if (gqlMessage) {
      dbPutMessage(gqlMessage);
      return gqlMessage;
    }
    throw new Error('Message not found');
  } catch (error) {
    logger.error(error, { id });
    return null;
  }
};
const onReceiveMessage = async (message: MessageType) => {
  try {
    if (message.__typename === 'Chat' || message.__typename === 'Comment') {
      const roomId = message.fromId;
      updateRoom(roomId, message);
    }
    await dbPutMessage(message);
    return;
  } catch (error) {
    logger.error(error);
    return;
  }
};
const onSendMessage = async (message: MessageType) => {
  try {
    if (message.__typename === 'Chat' || message.__typename === 'Comment') {
      const roomId = message.destId;
      updateRoom(roomId, message);
    }
    await dbPutMessage(message);
    return;
  } catch (error) {
    logger.error(error);
    return;
  }
};

/** API 系 */
export const postChat = async (props: PostChatProps) => {
  const { chat } = await API.postChat(props);
  if (chat) onSendMessage(chat);
  else throw new Error('Continuous message throwing');
};
export const postComment = async (props: PostCommentProps) => {
  const comment = await API.postComment(props);
  if (comment) onSendMessage(comment);
};
export const postConcern = async (props: PostConcernProps) => {
  const concern = await API.postConcern(props);
  if (concern) onSendMessage(concern[0]);
};
export const getConcerns = async (nextToken?: string) => {
  const res = await API.getConcerns({ limit: 50, nextToken });
  if (res) {
    res.messages.map(dbPutMessage);
    return res.messages;
  } else {
    return [];
  }
};

export const syncAllMessages = async () => {
  try {
    const lastMessage = await dbGetLatestMessage();
    const lastSync = lastMessage ? lastMessage.timestamp : 0;
    const graphQlProps = { limit: 100, lastSync };
    const incomingMessages = await API.syncIncomingMessagesAll(graphQlProps).then((messages) => {
      messages.forEach(onReceiveMessage);
      return messages;
    });
    const outgoingMessages = await API.syncOutgoingMessagesAll(graphQlProps).then((messages) => {
      messages.forEach(onSendMessage);
      return messages;
    });
    return [...incomingMessages, ...outgoingMessages];
  } catch (error) {
    logger.error(error);
  }
};

export const subscribeMessage = async (destId: string, cb: (_message: MessageType) => void) => {
  try {
    const subscription = API.postedChat({ destId }, (message) => {
      if (message) {
        onReceiveMessage(message);
        return cb(message);
      }
    });
    logger.log('start subscribe message', { destId });
    return subscription;
  } catch (error) {
    logger.error(error);
  }
};
