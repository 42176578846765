import React, { useEffect, useState } from 'react';

import { Counselor } from 'graphql/graphql-interfaces';
import { uniqueArray } from 'lib/utils';
import GraphQLService from 'services/graphql-service';
import CounselorInSearch from 'components/CounselorInSearch';
import logger from 'lib/logger';
import { putUser } from 'controllers';
import FlexContainer from 'layouts/FlexContainer';
import { Container } from '@mui/material';

const Search: React.FC = () => {
  const [counselors, setCounselors] = useState<Counselor[]>([]);

  useEffect(() => {
    GraphQLService.listCounselors({ limit: 10 })
      .then((data) => {
        if (data) {
          data.users.forEach(putUser);
          setCounselors(uniqueArray(data.users));
        }
      })
      .catch((err) => logger.error(err));
  }, []);

  return (
    <FlexContainer component={Container} maxWidth="sm" sx={{ padding: 1 }}>
      {counselors.map((counselor) => (
        <CounselorInSearch counselor={counselor} key={counselor.id} />
      ))}
    </FlexContainer>
  );
};

export default Search;
