import { userFragment } from 'graphql/fragments';

export const createUser = `
mutation (
  $firstName: String
  $lastName: String
  $gender: String
  $profileImage: String
  $zipCode: String
  $birthDate: Int
  ){
  createUser(
    firstName: $firstName
    lastName: $lastName
    gender: $gender
    profileImage: $profileImage
    zipCode: $zipCode
    birthDate: $birthDate
  ){
    userId
  }
}
`;

export const updatePatient = `
mutation (
  $gender: String
  $profileImage: String
  $zipCode: String
){
  updatePatient(
    gender: $gender,
    profileImage: $profileImage,
    zipCode: $zipCode
  ){
    ${userFragment}
  }
}`;

export const updateCounselor = `
mutation (
  $gender: String
  $zipCode: String
  $profileImage: String
  $experts: [String]
  $description: String
){
  updateCounselor(
    gender: $gender,
    zipCode: $zipCode,
    profileImage: $profileImage,
    experts: $experts,
    description: $description
  ){
    ${userFragment}
  }
}`;

export const postComment = `
mutation (
  $destId: String!,
  $concernId: String!,
  $attachments: AWSJSON
) {
  postComment (
    destId: $destId,
    concernId: $concernId,
    attachments: $attachments
  ) {
    id
    fromId
    destId
    concernId
    timestamp
    text
  }
}`;

export const postConcern = `
mutation (
  $attachments: AWSJSON
) {
  postConcern (
    attachments: $attachments
  ) {
    __typename
    id
    timestamp
    fromId
    destId
    category
    genre
    text
  }
}`;

export const postChat = `
mutation (
  $destId: String!,
  $timestamp: Int,
  $token: String!,
  $attachments: AWSJSON
) {
  postChat (
    destId: $destId,
    timestamp: $timestamp,
    token: $token,
    attachments: $attachments
  ) {
    __typename
    id
    timestamp
    fromId
    destId
    text
  }
}`;

export const createPlan = `
mutation (
  $planName: String!,
  $planDescription: String,
  $serviceType: ServiceType!,
  $serviceUnit: ServiceUnit!,
  $minutes: Int!,
  $price: Int!
) {
  createPlan (
    planName: $planName,
    planDescription: $planDescription,
    serviceType: $serviceType,
    serviceUnit: $serviceUnit,
    minutes: $minutes,
    price: $price
  )
  {
    planId
  }
}`;

export const updatePlan = `
mutation (
  $planId: String!,
  $planName: String,
  $planDescription: String,
  $serviceType: ServiceType,
  $serviceUnit: ServiceUnit,
  $minutes: Int,
  $price: Int,
  $active: Boolean
) {
  updatePlan (
    planId: $planId,
    planName: $planName,
    planDescription: $planDescription,
    serviceType: $serviceType,
    serviceUnit: $serviceUnit,
    minutes: $minutes,
    price: $price,
    active: $active
  )
  {
    planId
  }
}`;

export const createAvailableTime = `
mutation (
  $startTimestamp: Int!
  $endTimestamp: Int!
  $reserveDeadlineTimestamp: Int
  $serviceType: ServiceType
) {
  createAvailableTime (
    startTimestamp: $startTimestamp,
    endTimestamp: $endTimestamp,
    reserveDeadlineTimestamp: $reserveDeadlineTimestamp,
    serviceType: $serviceType
  )
  {
    availableTimeId
  }
}`;

export const deleteAvailableTime = `
mutation (
  $availableTimeId: String!
) {
  deleteAvailableTime (
    availableTimeId: $availableTimeId
  )
  {
    availableTimeId
  }
}`;

export const cancelReservation = `
mutation (
  $reservationId: String!
) {
  cancelReservation (
    reservationId: $reservationId
  )
  {
    refundPrice
  }
}`;

export const createPaymentAccount = `
mutation (
  $accountType: String!
) {
  createPaymentAccount (
    accountType: $accountType
  )
  {
    stripeId
  }
}`;

export const createCounselingRoom = `
mutation (
  $adminCounselor: String
  $name: String!
) {
  createCounselingRoom (
    adminCounselor: $adminCounselor
    name: $name
  )
  {
    counselingRoomId
  }
}`;

export const updateCounselingRoom = `
mutation (
  $counselingRoomId: String!
  $name: String
  $description: String
  $address: String
  $phoneNumber: String
  $profileImage: String
  $active: Boolean
) {
  updateCounselingRoom (
    counselingRoomId: $counselingRoomId
    name: $name
    description: $description
    address: $address
    phoneNumber: $phoneNumber
    profileImage: $profileImage
    active: $active
  )
  {
    counselingRoomId
  }
}`;

export const createVideoMeeting = `
mutation (
    $reservationId: String!
    $token: String!
) {
  createVideoMeeting (
    reservationId: $reservationId
    token: $token
  ) {
    meeting
    attendee
  }
}`;

export const attendVideoMeeting = `
mutation (
    $reservationId: String!
    $token: String!
) {
  attendVideoMeeting (
    reservationId: $reservationId
    token: $token
  ) {
    meeting
    attendee
  }
}`;
