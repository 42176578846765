import React from 'react';
import { useHistory } from 'react-router-dom';

import { AppBar, IconButton, SxProps, Theme, Typography, useTheme } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FullScreen from './FullScreen';
import FlexContainer from './FlexContainer';

import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';

interface Props {
  children?: React.ReactNode;
  title: string;
  containerSx?: SxProps<Theme>;
  disableBackButton?: boolean;
  optionalButton?: {
    component: React.ReactNode;
    href?: string;
    onClick?: () => void;
  };
}

const AppPage: React.FC<Props> = (props) => {
  const { children, disableBackButton = false, title, optionalButton, containerSx } = props;
  const history = useHistory();
  const theme = useTheme();
  const meetingManager = useMeetingManager();

  const onClickBack = () => {
    history.goBack();
    if (meetingManager.audioVideo) {
      meetingManager.leave().finally(() => {
        meetingManager.initializeMeetingManager();
      });
    }
  };

  return (
    <FullScreen title={title}>
      <AppBar position="sticky" sx={{ boxShadow: 'none' }}>
        {!disableBackButton && (
          <IconButton size="large" onClick={onClickBack} sx={{ position: 'absolute', top: 0, left: 0 }}>
            <ArrowBackIosNewOutlinedIcon sx={{ color: theme.palette.primary.contrastText }} />
          </IconButton>
        )}
        <Typography variant="h6" m={1} align="center" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {optionalButton && (
          <IconButton
            size="large"
            {...{
              href: optionalButton.href,
              onClick: optionalButton.onClick,
            }}
            sx={{ position: 'absolute', top: 0, right: 0 }}
          >
            {optionalButton.component}
          </IconButton>
        )}
      </AppBar>
      <FlexContainer sx={containerSx}>{children}</FlexContainer>
    </FullScreen>
  );
};

export default AppPage;
