export const userFragment = `
__typename

... on Patient {
  id
  username
  group
  version
  profileImage

  birthDate
  gender
  firstName
  lastName
}

... on PrivatePatient {
  id
  username
  group
  version
  profileImage

  birthDate
  gender
  firstName
  lastName

  zipCode

  registeredTimestamp
  updatedTimestamp
  ban
}

... on Counselor {
  id
  username
  group
  version
  profileImage

  birthDate
  gender
  firstName
  lastName

  qualificationEvidences {
    __typename
    ... on UnconfirmedQualificationEvidence {
      evidenceConfirmationStatus
      ticketId
    }
    ... on ConfirmedCPQualificationEvidence {
      qualificationName
      evidenceConfirmationStatus
      expiredTimestamp
      confirmedTimestamp
    }
    ... on ConfirmedCPPQualificationEvidence {
      qualificationName
      confirmedTimestamp
      evidenceConfirmationStatus
    }
  }
  experts
  counselingRoomId
  description
}

... on PrivateCounselor {
  id
  username
  group
  version
  profileImage

  birthDate
  gender
  firstName
  lastName

  qualificationEvidences {
    __typename
    ... on UnconfirmedQualificationEvidence {
      evidenceConfirmationStatus
      ticketId
    }
    ... on ConfirmedCPQualificationEvidence {
      qualificationName
      evidenceConfirmationStatus
      expiredTimestamp
      confirmedTimestamp
    }
    ... on ConfirmedCPPQualificationEvidence {
      qualificationName
      confirmedTimestamp
      evidenceConfirmationStatus
    }
  }
  experts
  counselingRoomId
  description

  zipCode

  registeredTimestamp
  updatedTimestamp
  ban

  stripeId
  stripeCurrentDisabledReason
  stripeFutureDisabledReason
}
`;
export const chatFragment = `
__typename

... on Chat {
  id
  fromId
  destId
  timestamp

  text
}

... on Comment {
  id
  fromId
  destId
  concernId
  timestamp

  text
}

... on Concern {
  id
  fromId
  destId
  timestamp

  text
  genre
  category
}

... on Karte {
  id
  fromId
  destId
  timestamp

  condition
  medicine
  summary
  assessment
  plan
  memo
}`;

export const calendarItemFragment = `
__typename

... on Reservation {
  id
  serviceGroup
  serviceType

  serviceUnit

  planId
  price
  omamoriPlatformFee
  startTimestamp
  endTimestamp

  counselingMajorClassification
  counselingMinorClassification
  counselingContent
  counselingComment

  registeredTimestamp
  reservedTimestamp
  expirationTimestamp
  chargeId
  serviceStatus
  counselingRoomId
  patientId
  counselorId

  transferId

  cancelRequestTimestamp
  refundId
  refundCompleteTimestamp
  refundPrice
  counselorChargeId
  counselorChargeCompleteTimestamp
  counselorChargePrice
  cancelReason
}

... on AvailableTime {
  id
  serviceGroup
  serviceType
  registeredTimestamp

  startTimestamp
  endTimestamp

  reserveDeadlineTimestamp

  counselorId
}
`;

export const publicCalendarItemFragment = `
__typename

... on PublicReservation {
  id
  serviceGroup
  registeredTimestamp

  startTimestamp
  endTimestamp

  registeredTimestamp
  reservedTimestamp
  expirationTimestamp
  serviceStatus

  counselorId
}

... on AvailableTime {
  id
  serviceGroup
  serviceType
  registeredTimestamp

  startTimestamp
  endTimestamp

  reserveDeadlineTimestamp

  counselorId
}
`;
