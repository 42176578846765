import React, { useEffect, useState } from 'react';

import { Avatar, Backdrop, CircularProgress, Container, List, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';

import PagePaths from 'pagepaths';
import ListItemLink from 'components/ListItemLink';
import { Reservation, UserType } from 'graphql/graphql-interfaces';
import { getNextReservation, RoomModelRaw, useInbox } from 'db';
import { getUser } from 'controllers';
import FlexContainer from 'layouts/FlexContainer';
import logger from 'lib/logger';

dayjs.locale('ja');
dayjs.extend(relativeTime);

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    margin: 0,
    padding: 0,
    textAlign: 'center',
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const constructNextReservationMessage = (reservation: Reservation) => {
  const ts = dayjs.unix(reservation.startTimestamp).format('YYYY/MM/DD HH:mm');
  return `  [予約: ${ts}~]`;
};

const fallbackElement = <></>;

interface RoomProps {
  room: RoomModelRaw;
}
const Room: React.FC<RoomProps> = ({ room }) => {
  const [user, setUser] = useState<UserType | null>();
  const [reservation, setReservation] = useState<Reservation | null>();
  useEffect(() => {
    logger.log('room.id', room.id);
    getUser(room.id).then(setUser);
    getNextReservation(room.id).then(setReservation);
  }, [room.id]);

  if (user === null || (room.lastMessage === undefined && room.reservation === undefined)) {
    logger.log('Invalid room', { room });
    return fallbackElement;
  }
  const nextReservationMessage = reservation ? constructNextReservationMessage(reservation) : '';
  const fullname = user?.lastName + ' ' + user?.firstName + nextReservationMessage;
  return user ? (
    <ListItemLink
      key={`inbox-${user.id}`}
      to={PagePaths.talk + '/' + user.id}
      avatar={<Avatar alt={fullname} src={user.profileImage || ''} />}
      primary={fullname}
      secondary={room.lastMessage ? room.lastMessage.text.slice(0, 50) : undefined}
      alignItems="flex-start"
      time={room.lastMessage ? dayjs.unix(room.lastMessage.timestamp).fromNow() : undefined}
    />
  ) : (
    <></>
  );
};

const Inbox: React.FC = () => {
  const classes = useStyles();
  const rooms = useInbox();
  logger.log(`rooms: ${rooms}`);

  return (
    <FlexContainer component={Container} maxWidth="sm" sx={{ padding: 0 }}>
      <Backdrop className={classes.backdrop} open={!rooms}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <List className={classes.content}>
        {rooms && rooms.length ? (
          rooms.map((room) => <Room room={room} key={room.id} />)
        ) : (
          <Typography>相談履歴がありません</Typography>
        )}
      </List>
    </FlexContainer>
  );
};

export default Inbox;
