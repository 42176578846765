import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { putReservation } from 'db';

import { CalendarItem, Reservation } from 'graphql/graphql-interfaces';
import logger from 'lib/logger';
import API from 'services/graphql-service';

const isReservation = (item: CalendarItem): item is Reservation => item.__typename === 'Reservation';

export const listMyCalendarItems = async () => {
  try {
    const startTimestamp = dayjs().add(-1, 'day').unix();
    const limit = 100;
    return API.listSelfCalendarItems({ limit, startTimestamp, endTimestamp: null });
  } catch (error) {
    logger.error(error);
  }
  return null;
};

export const listCounselorCalendarItems = async (id: string) => {
  const res = await API.listCounselorCalendarItems({
    counselorId: id,
    limit: 100,
    startTimestamp: dayjs().add(-1, 'day').unix(),
    endTimestamp: null,
  });
  return res;
};

export const getReservations = async () => {
  try {
    const calendarItems = await listMyCalendarItems();
    if (calendarItems) {
      const reservations = calendarItems.filter(isReservation);
      reservations.forEach(putReservation);
      return reservations;
    }
    return null;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
