/*
 * 各ページのパス
 * 末尾に '/' はつけない
 */
export const PagePaths = {
  root: '/',

  /**
   * production 以外で使用可能
   */
  test: '/test',

  /**
   * 認証不要のページ
   */
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy-policy',
  tokushouhou: '/tokushouhou',
  cancelPolicy: '/cancel-policy',
  clear: '/clear',
  notfound: '/not-found',
  welcome: '/welcome',

  /**
   * '/auth' 以下は認証関係
   */
  auth: '/auth',
  signup: '/auth/sign-up',
  signin: '/auth/sign-in',
  verifyPhone: '/auth/verify-phone',
  verifyEmail: '/auth/verify-email',
  signout: '/auth/sign-out',
  recovery: '/auth/recovery',
  resetPassword: '/auth/reset-password',
  changeEmail: '/auth/change-email',
  changePassword: '/auth/change-password',
  counselor: '/counselor',

  /**
   * '/app' 以下は認証が必要
   * '/app' 直下はホーム画面
   */
  app: '/app',

  /**
   * 通知
   */
  notification: '/app/notification',
  news: '/app/notification',

  /**
   * 検索
   */
  search: '/app/search',

  /**
   * タイムライン
   */
  timeline: '/app/timeline',
  concern: '/app/timeline/concern',
  comment: '/app/timeline/comment',
  sentComment: '/app/timeline/sent-comment',

  /**
   * メッセージ
   */
  inbox: '/app/inbox',
  talk: '/app/inbox/talk',
  videoRoom: '/app/inbox/video-room',

  /**
   * マイページ関係
   */
  mypage: '/app/mypage',
  createPlan: '/app/mypage/create-plan',
  createAvailableTime: '/app/mypage/create-available-time',
  afterConcern: '/app/mypage/complete-concern',
  myCalendar: '/app/mypage/calendar',
  myPlan: '/app/mypage/plans',
  setting: '/app/mypage/setting',

  /**
   * アカウント登録関係
   */
  account: '/account',
  registerAttributes: '/account/register-attributes',
  updateAttributes: '/account/update-attributes',
  registerStripeAccount: '/account/register-payment',
  registerQualificationEvidences: '/account/register-qualification-evidences',
  waitStripeAccountConfirmation: '/account/wait-payment-confirmation',
  waitQualificationEvidenceConfirmation: '/account/wait-qualification-confirmation',
  banned: '/account/banned',

  /**
   * 予約関係
   */
  reserve: '/app/reserve',
  reserveCompleted: '/app/reserve-completed',
};

export default PagePaths;
