import { Container } from '@mui/material';
import FlexContainer from 'layouts/FlexContainer';
import React, { useEffect } from 'react';

const Notification: React.FC = () => {
  useEffect(() => {
    // scriptを読み込み
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(script);
    // アンマウント時に一応scriptタグを消しておく
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <FlexContainer component={Container} maxWidth="sm" sx={{ padding: '8px 0' }}>
      <a className="twitter-timeline" href="https://twitter.com/OM_counseling?ref_src=twsrc%5Etfw">
        Tweets by OM_counseling
      </a>
    </FlexContainer>
  );
};

export default Notification;
